import { useState } from "react";

import { Modal, Button } from "shared/components";

import CreateDashboard from "./screens/CreateDashboard";
import EditDashboard from "./screens/EditDashboard";

import styles from "./styles.module.scss";

import { Tabs } from "antd";

const SCREENS = {
	CREATE: "create",
	EDIT: "edit",
};

const ITEMS = [
	{
		key: SCREENS.CREATE,
		label: `Создать Дэшборд`,
	},
	{
		key: SCREENS.EDIT,
		label: `Редактировать роли`,
	},
];

const DashboardSettings = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [screen, setScreen] = useState(SCREENS.CREATE);

	const handleChangeScreen = (screen: string) => {
		setScreen(screen);
	};

	return (
		<>
			<Button onClick={() => setOpen(true)}>+ Редактировать</Button>
			<Modal
				open={open}
				onCancel={() => setOpen(false)}
				className={styles.dashboardSettingsModal}
			>
				<Tabs
					defaultActiveKey={SCREENS.CREATE}
					items={ITEMS}
					onChange={(activeKey) => {
						handleChangeScreen(activeKey);
					}}
				/>

				<div className={styles.modalContainer}>
					{SCREENS.CREATE === screen && <CreateDashboard />}
					{SCREENS.EDIT === screen && <EditDashboard />}
				</div>
			</Modal>
		</>
	);
};

export default DashboardSettings;
