import * as yup from "yup";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import MultiSelect from "components/MultiSelect";
import { useFormik } from "formik";
import { Form, Input, Button, Result, Checkbox } from "antd";

import Error from "components/Error";
import ConditionalRender from "components/ConditionalRender";
import { useHistory as useEventHistory, EVENTS } from "api/History";
import { useProfile } from "hooks/useContext";

import styles from "./styles.module.scss";
import axios from "utils/axios";

export const DecisionTab = ({ data, update }) => {
	const [tags, setTags] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isChanging, setIsChanging] = useState(false);
	const { createHistory } = useEventHistory();
	const { user, isAdmin } = useProfile();
	const handleSave = async (value) => {
		const chatId = data?.merchants?.[0]?.chatId ?? false;
		const { name } = user;
		try {
			await axios.put(`/ticket/${data.id}/tempDecision`, {
				...value,
				author: name,
				chatId,
			});

			if (
				value.tags.includes(7) &&
				(!data.tags || !data.tags.some((tag) => tag.id === 7))
			) {
				await createHistory({
					ticketId: data.id,
					eventId: EVENTS.ADD_DEVELOP_TAG,
				});
			}

			if (isChanging) {
				await createHistory({
					ticketId: data.id,
					eventId: EVENTS.EDIT_WORKAROUND,
				});
			} else {
				await createHistory({
					ticketId: data.id,
					eventId: EVENTS.ADD_WORKAROUND,
				});
			}

			setIsChanging(false);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
			update();
		}
	};
	const Label = ({ value }) => {
		return <div className={styles.label}>{value}</div>;
	};

	const getTags = useCallback(async () => {
		const res = await axios.get("/tag", {
			params: { type: 1 },
		});
		setTags(res.data);
	}, []);

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		onSubmit: (values) => handleSave(values),
		initialValues: {
			tempDecision: data.tempDecision ? data.tempDecision : "",
			tags: data.tags?.length > 0 ? data.tags?.map((item) => item.id) : [],
			sentMerchantMessage: false,
			merchantMessage: "",
		},
		validationSchema: yup.object().shape({
			tempDecision: yup.string().required("Это поле обязательно"),
		}),
		enableReinitialize: true,
	});

	const handleChange = (name) => (event) => {
		setFieldValue(name, event.target.value);
	};

	const handleChanging = () => {
		setIsChanging(!isChanging);
	};

	const optionsTags = useMemo(() => {
		return tags.map((item) => ({ id: item.id, label: item.name }));
	}, [tags]);

	const getTagsByListId = useCallback(
		(tagsIds) => {
			let list = [];

			tagsIds.map((tagId) => {
				optionsTags.some((item) => {
					if (item.id === tagId) {
						list.push(item.label);
					}
				});
			});
			return list.join(", ");
		},

		[optionsTags]
	);

	useEffect(() => {
		getTags();
	}, []);

	const handleChangeSelect = (name) => (value) => {
		setFieldValue(name, value);
	};

	const handleChangeCkeckbox = (name) => (event) => {
		setFieldValue(name, event.target.checked);
	};

	const statusClosedAt = data.closedAt;

	const currentUser = data?.user?.id === user.id;
	const isSourceClient = data?.source?.id === 1;
	const conditionSentMerchantMessage = isChanging && !isSourceClient;

	if (data.tempDecision !== null) {
		return (
			<div>
				<Form
					labelCol={{
						span: 3,
					}}
					wrapperCol={{
						span: 10,
					}}
					layout="vertical"
				>
					<Form.Item
						label="Описание решения"
						extra={
							touched.tempDecision && <Error message={errors.tempDecision} />
						}
						required={isChanging}
						style={{ fontWeight: !isChanging ? 500 : 400 }}
					>
						{!isChanging ? (
							<Label value={values.tempDecision} />
						) : (
							<Input.TextArea
								value={values.tempDecision}
								onChange={handleChange("tempDecision")}
								disabled={!isChanging}
								autoSize={{ minRows: 5 }}
							/>
						)}
					</Form.Item>
					{conditionSentMerchantMessage && (
						<Form.Item
							label={
								<>
									<Checkbox
										checked={values.sentMerchantMessage}
										onChange={handleChangeCkeckbox("sentMerchantMessage")}
										style={{ marginRight: "5px" }}
									/>
									Отправить сообщение мерчанту
								</>
							}
						>
							{values.sentMerchantMessage && (
								<Input.TextArea
									value={values.merchantMessage}
									disabled={!values.sentMerchantMessage}
									onChange={handleChange("merchantMessage")}
									className={styles.answerTextarea}
									rows="6"
								/>
							)}
						</Form.Item>
					)}

					<Form.Item
						label="Теги"
						extra={touched.tags && <Error message={errors.tags} />}
						style={{ fontWeight: !isChanging ? 500 : 400 }}
					>
						{!isChanging ? (
							<Label value={getTagsByListId(values.tags)} />
						) : (
							<MultiSelect
								options={optionsTags}
								placeholder="Выберите Теги"
								mode="multiple"
								disabled={!isChanging}
								value={values.tags}
								onChange={handleChangeSelect("tags")}
							/>
						)}
					</Form.Item>

					{((statusClosedAt ? false : currentUser) || isAdmin) && (
						<ConditionalRender roles={[1, 2]}>
							<Button
								loading={loading}
								type="primary"
								onClick={!isChanging ? handleChanging : handleSubmit}
							>
								{!isChanging ? "Изменить" : "Сохранить"}
							</Button>
						</ConditionalRender>
					)}
				</Form>
			</div>
		);
	}

	if (data.status?.id === 5 || data.status?.id === 6) {
		return <Result status="success" title="Обращение решено" />;
	}
	if (data.status?.id === 1 || data.status?.id === 2) {
		return <Result status="info" title="Для начала проведите анализ" />;
	}

	return (
		<Form
			labelCol={{
				span: 3,
			}}
			wrapperCol={{
				span: 10,
			}}
			layout="vertical"
		>
			<Form.Item
				required
				label="Решение"
				extra={touched.tempDecision && <Error message={errors.tempDecision} />}
			>
				<Input.TextArea
					value={values.tempDecision}
					onChange={handleChange("tempDecision")}
					autoSize={{ minRows: 5 }}
				/>
			</Form.Item>

			{conditionSentMerchantMessage && (
				<Form.Item
					label={
						<>
							<Checkbox
								checked={values.sentMerchantMessage}
								onChange={handleChangeCkeckbox("sentMerchantMessage")}
								style={{ marginRight: "5px" }}
							/>
							Отправить сообщение мерчанту
						</>
					}
				>
					{values.sentMerchantMessage && (
						<Input.TextArea
							value={values.merchantMessage}
							disabled={!values.sentMerchantMessage}
							onChange={handleChange("merchantMessage")}
							className={styles.answerTextarea}
							rows="6"
						/>
					)}
				</Form.Item>
			)}
			<Form.Item
				label="Теги"
				extra={touched.tags && <Error message={errors.tags} />}
				style={{ fontWeight: 400 }}
			>
				<MultiSelect
					options={optionsTags}
					placeholder="Выберите Теги"
					mode="multiple"
					value={values.tags}
					onChange={handleChangeSelect("tags")}
				/>
			</Form.Item>
			{((statusClosedAt ? false : currentUser) || isAdmin) && (
				<Button loading={loading} type="primary" onClick={handleSubmit}>
					Сохранить
				</Button>
			)}
		</Form>
	);
};
