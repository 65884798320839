import React, { useEffect, useState } from "react";
import styles from "../AnswerDeveloperAwait/styles.module.scss";

import axios from "utils/axios";
import Loading from "shared/components/Loading";

import { useDashboard } from "pages/Dashboard";
import dayjs from "dayjs";
import { dateToApiDateFormat } from "utils/helpers";

import { Button } from "shared/components/Button";
import { Modal } from "shared/components";

const TimeAnalyzeRequestExpired: React.FC = () => {
	const [open, setOpen] = useState(false);
	const { filter, restart } = useDashboard();
	const [data, setData] = useState<{ list: []; total: 0 }>({
		total: 0,
		list: [],
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [total, setTotal] = useState(0);
	const fetchData = async () => {
		setLoading(true);

		const response = await axios
			.get(`/widget/time-analyze-expired`, {
				params: {
					ticketDateStart: dateToApiDateFormat(filter.dateStart),
					ticketDateEnd: dateToApiDateFormat(filter.dateEnd),
				},
			})
			.finally(() => setLoading(false));

		const dataRes = response?.data || { list: [], total: 0 };

		setTotal(dataRes.total);
		setData(dataRes);

		return response;
	};

	const handlerOnClick = (id: number) => {
		if (!id) return;
		window.open(`/admin/ticket/${id}`);
	};

	const timeDifference = (date: string) => {
		const startTime = dayjs(date);
		const endTime = dayjs();

		const diffMs = endTime.diff(startTime);

		const hours = Math.floor(diffMs / (1000 * 60 * 60));
		const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

		return `${hours}ч, ${minutes}м, ${seconds}с`;
	};

	useEffect(() => {
		fetchData();
	}, [filter, restart]);

	const isShowMore = data.list.length > 5;

	return (
		<Loading loading={loading} className={styles.widgetLoading}>
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.title}>Истекло время на анализ обращения</div>
					<div className={styles.count}>{total}</div>
				</div>
				<div className={styles.body}>
					<ul className={styles.list}>
						<li className={styles.head}>
							<span>Обращение</span>
							<span>Длительность</span>
						</li>
						{data.list.slice(0, 5).map((item) => (
							<li
								key={item.id}
								className={styles.row}
								onClick={() => handlerOnClick(item.id)}
							>
								<span>№{item.id}</span>

								<span>{timeDifference(item.historyCreatedAt)}</span>
							</li>
						))}
					</ul>

					<div className={styles.actions}>
						{isShowMore && (
							<Button
								type="link"
								style={{ color: "#3BC19A" }}
								onClick={() => setOpen(true)}
							>
								Посмотреть все
							</Button>
						)}
					</div>
				</div>
			</div>
			<Modal open={open} onCancel={() => setOpen(false)}>
				<ul
					className={styles.list}
					style={{ maxHeight: 300, overflowY: "scroll" }}
				>
					<li className={styles.head}>
						<span>Обращение</span>
						<span>Длительность</span>
					</li>
					{data.list.map((item) => (
						<li
							key={item.id}
							className={styles.row}
							onClick={() => handlerOnClick(item.id)}
						>
							<span>№{item.id}</span>

							<span>{timeDifference(item.historyCreatedAt)}</span>
						</li>
					))}
				</ul>
			</Modal>
		</Loading>
	);
};

export default TimeAnalyzeRequestExpired;
