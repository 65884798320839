import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory as useEventHistory, EVENTS } from "api/History";
import Error from "components/Error";
import SingleSelect from "components/SingleSelect";
import ConditionalRender from "components/ConditionalRender";

import axios from "utils/axios";

import styles from "./styles.module.scss";

export const SystemDecisionTab = ({ data, update }) => {
	const [loading, setLoading] = useState(false);
	const [problems, setProblems] = useState([]);
	const [isChanging, setIsChanging] = useState(false);
	const { createHistory } = useEventHistory();

	const history = useHistory();

	const getProblems = useCallback(async () => {
		const res = await axios.get("/problems/select");
		setProblems(res.data);
	}, []);

	useEffect(() => {
		if (!problems.length) {
			getProblems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const optionsSysProblems = useMemo(() => {
		const p = problems.map((item) => ({
			id: item.id,
			label: `id: ${item.id} ${item.name}`,
		}));

		if (data.systemDecision) {
			const { id, name } = data.systemDecision;
			p.push({
				id: id,
				label: `id: ${id} ${name}`,
			});
		}

		return p;
	}, [problems, data]);

	const handleSave = async (value) => {
		try {
			setLoading(true);
			await axios.put(`/ticket/${data.id}/problem`, value);

			if (isChanging) {
				await createHistory({
					ticketId: data.id,
					eventId: EVENTS.EDIT_SYSTEM_PROBLEM,
				});
			} else {
				await createHistory({
					ticketId: data.id,
					eventId: EVENTS.ADD_SYSTEM_PROBLEM,
				});
			}

			setIsChanging(false);
			resetForm();
			update();
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const Label = ({ value }) => {
		return <div className={styles.label}>{value}</div>;
	};

	const optionsProblems = [
		{
			id: 1,
			label: "Создать новую",
		},
		{
			id: 2,
			label: "Связать с существующей",
		},
		{
			id: 3,
			label: "Не выявлена",
		},
	];

	const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
		useFormik({
			onSubmit: (values) => handleSave(values),
			initialValues: {
				problem: data.systemDecision ? 2 : 3,
				name: data.systemDecision?.name || "",
				description: data.systemDecision?.description || "",
				decisionId: data.systemDecision?.id || "",
			},
			validationSchema: yup.object().shape({
				problem: yup.string().required("Это поле обязательно"),
				name: yup.string().when("problem", {
					is: "1",
					then: yup.string().required("Это поле обязательно"),
				}),
				description: yup.string().when("problem", {
					is: "1",
					then: yup.string().required("Это поле обязательно"),
				}),
				decisionId: yup.string().when("problem", {
					is: "2",
					then: yup.string().required("Это поле обязательно"),
				}),
			}),
			enableReinitialize: true,
		});

	const handleChange = (name) => (event) => {
		setFieldValue(name, event.target.value);
	};

	const handleClickOnLink = () => {
		history.push(`/admin/problem/${values.decisionId}`);
	};

	const handleChangeSelect = (name) => (value) => {
		setFieldValue(name, value);
	};

	const handleCancelEdit = () => {
		setIsChanging(false);
	};

	const handleEditForm = async () => {
		setIsChanging(true);
	};

	const getProblemById = useCallback(
		(problemId) => {
			return optionsProblems.find((item) => item.id === problemId)?.label;
		},

		[optionsProblems]
	);

	const getProblemSysById = useCallback(
		(problemId) => {
			return optionsSysProblems.find((item) => item.id === problemId)?.label;
		},

		[optionsSysProblems]
	);

	const conditionSysProblem =
		!isChanging &&
		(data.systemDecisionDone === 1 || data.systemDecisionDone === 0);
	const conditionProblem = !isChanging && data.systemDecisionDone;
	return (
		<Form
			labelCol={{
				span: 8,
			}}
			wrapperCol={{
				span: 10,
			}}
			layout="vertical"
			onFinish={handleSubmit}
		>
			<Form.Item
				label="Системная проблема"
				extra={touched.problem && <Error message={errors.problem} />}
				required={!conditionSysProblem}
				style={{ fontWeight: conditionSysProblem ? 500 : 400 }}
			>
				{conditionSysProblem ? (
					<Label value={getProblemById(values.problem)} />
				) : (
					<SingleSelect
						placeholder="Проблема"
						options={optionsProblems}
						disabled={conditionSysProblem}
						onChange={handleChangeSelect("problem")}
						value={values.problem}
					/>
				)}
			</Form.Item>

			{values.problem === 1 && (
				<>
					<Form.Item
						required
						label="Название"
						extra={touched.name && <Error message={errors.name} />}
					>
						<Input
							value={values.name}
							onChange={handleChange("name")}
							placeholder="Название проблемы"
						/>
					</Form.Item>
					<Form.Item
						required
						label="Описание проблемы на техническом языке"
						extra={
							touched.description && <Error message={errors.description} />
						}
					>
						<Input.TextArea
							value={values.description}
							onChange={handleChange("description")}
							autoSize={{ minRows: 5 }}
						/>
					</Form.Item>
				</>
			)}

			{values.problem === 2 && (
				<Form.Item
					label="Проблема"
					extra={touched.decisionId && <Error message={errors.decisionId} />}
					required={!optionsSysProblems}
					style={{ fontWeight: optionsSysProblems ? 500 : 400 }}
				>
					{conditionProblem ? (
						<Label value={getProblemSysById(values.decisionId)} />
					) : (
						<SingleSelect
							placeholder="Выберите проблему"
							options={optionsSysProblems}
							disabled={conditionProblem}
							onChange={handleChangeSelect("decisionId")}
							value={values.decisionId}
							showSearch
						/>
					)}

					{values.decisionId && !isChanging && (
						<Button
							onClick={handleClickOnLink}
							type="default"
							className={styles.linkToProblem}
						>
							Системная проблема
						</Button>
					)}
				</Form.Item>
			)}
			<ConditionalRender roles={[1, 2]}>
				{data.systemDecisionDone === null && (
					<Button htmlType="submit" loading={loading} type="primary">
						Сохранить
					</Button>
				)}

				{(data.systemDecisionDone === 1 || data.systemDecisionDone === 0) && (
					<>
						<Button
							onClick={isChanging ? handleSubmit : handleEditForm}
							loading={loading}
							type="primary"
						>
							{isChanging ? "Сохранить" : "Изменить"}
						</Button>
						{isChanging && (
							<Button onClick={handleCancelEdit} type="default">
								Отменить
							</Button>
						)}
					</>
				)}
			</ConditionalRender>
		</Form>
	);
};
