import React, { useEffect, useState, useCallback } from "react";
import styles from "./styles.module.scss";
import axios from "utils/axios";
import Loading from "shared/components/Loading";
import BarChart from "../BarChart2";

import { useDashboard } from "pages/Dashboard";

import { dateToApiDateFormat } from "utils/helpers";

type UnclosedTicketsProps = {
	date?: string[];
};

const WidgetUnclosedIncident: React.FC<UnclosedTicketsProps> = () => {
	const { filter, restart } = useDashboard();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [total, setTotal] = useState(0);
	const fetchData = async () => {
		setLoading(true);

		const response = await axios
			.get(`/widget/unclosed-tickets`, {
				params: {
					group: 2,
					ticketDateStart: dateToApiDateFormat(filter.dateStart),
					ticketDateEnd: dateToApiDateFormat(filter.dateEnd),
				},
			})
			.finally(() => setLoading(false));

		const dataRes = response?.data || [];

		setTotal(dataRes.reduce((sum, item) => sum + item.total, 0));
		setData(dataRes);

		return response;
	};

	const handlerOnClick = (id: number) => {
		if (!id) return;
		window.open(
			`/admin/incidents?ticketDateStart=${filter.dateStart}&ticketDateEnd=${filter.dateEnd}&status=${id}`,
			"_blank"
		);
	};

	useEffect(() => {
		fetchData();
	}, [filter, restart]);

	return (
		<Loading loading={loading} className={styles.widgetLoading}>
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.title}>Не закрытые инциденты</div>
					<div className={styles.count}>{total}</div>
				</div>
				<BarChart data={data} onClick={(data) => handlerOnClick(data.id)} />
			</div>
		</Loading>
	);
};

export default WidgetUnclosedIncident;
