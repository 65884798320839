import { useCallback, useState } from "react";
import axios from "utils/axios";

export const EVENTS = {
	APPLY_IN_WORK: 1, // взятие в работу
	TAKE_JOB_DELEGATION: 2, // взятие в работу делегированием
	TRASFER_ANOTHER_EMPLOYEE: 3, // перекидывание обращения другому сотруднику
	EDIT_APPEAL: 4, // редактирование анализа обращения
	ADD_CASE_ANALYSIS: 5,
	EDIT_CASE_ANALYSIS: 6, // 'Редактирование анализа обращения',
	ADD_WORKAROUND: 7, //'Добавления временного решения',
	EDIT_WORKAROUND: 8, // 'Редактирование временного решения',
	ADD_SYSTEM_PROBLEM: 9, // 'Добавление/присвоение к системной проблеме',
	EDIT_SYSTEM_PROBLEM: 10, //'Изменение системной проблемы',
	CLOSE_APPEAL: 11, // 'Закрытие обращения'
	REPLY_USER: 12, //Ответ пользователю
	ADD_DEVELOP_TAG: 13, // В Тиккет добавили тег Ожидается ответ от команды разработчиков
};

export const useHistory = () => {
	const [loading, setLoading] = useState(false);

	const createHistory = useCallback(
		async ({ ticketId, eventId, meta = null }) => {
			const body = {
				ticketId,
				eventId,
			};

			if (meta) {
				body.meta = meta;
			}

			try {
				setLoading(true);
				const res = await axios.post(`/history/create`, body);
				return res;
			} catch (e) {
				console.log(e);
				return e;
			} finally {
				setLoading(false);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[]
	);

	return { createHistory, loading };
};
